<template>
    <ion-modal
        :is-open="isOpenModalAddObject"
        css-class="my-custom-class"
        @didDismiss="isOpenModalAddObject = false;"
    >
        <AddRouteObject
            v-model="isOpenModalAddObject"
            :route-object="routeObject"
            :edit="editPlacemark"
            @add-to-list="addRouteObjectToList"
            @update:route-object="updateRouteObject"
        />
    </ion-modal>

    <ion-modal
      :is-open="isOpenModalPointInfo"
      css-class="my-custom-class"
      @didDismiss="isOpenModalPointInfo = false;"
    >
        <ModalPointInfo
            :name="routeObject.name || ''"
            :desc="routeObject.desc || ''"
            :coords="routeObject.point || []"
            title="Информация об объекте"
            @close="isOpenModalPointInfo = false;"
        />
    </ion-modal>

    <ion-header>
        <ion-toolbar>
            <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
                <ion-col style="margin-left: 18px;">
                    <h4 style="margin: 16px 0;">
                      Добавление маршрута
                    </h4>
                </ion-col>
                
                <ion-col size="auto">
                    <ion-button
                        fill="clear"
                        @click="closeModal"
                    >
                        <ion-icon
                            slot="icon-only"
                            color="dark"
                            name="close"
                        ></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="map-container" style="display: flex; height: 100%">
        <div class="sidebar" style="padding: 20px">
          <div>
            <InputDefault
                ref="nameInput"
                :auto-grow="$isMobile"
                :model-value="name"
                :rules="[ v => !!v || 'Укажите название маршрута' ]"
                hide-length
                label="Название маршрута"
                position="floating"
                required
                @update:modelValue="val => updateFormInputs(val, 'name')"
            />
          </div>

          <div>
            <InputDefault
                ref="descInput"
                :max-length="5000"
                :model-value="desc"
                :rules="[ v => !!v || 'Укажите описание маршрута' ]"
                auto-grow
                label="Описание маршрута"
                position="floating"
                required
                @update:modelValue="val => updateFormInputs(val, 'desc')"
            />
          </div>

          <div
              v-if="allTrackPoints.length || !$isMobile"
              class="sidebar__heading"
          >
              Список объектов
          </div>

          <div
              v-if="allTrackPoints.length"
              class="sidebar__list"
          >
              <MapItemsList
                  :placemarks="allTrackPoints"
                  editable
                  ordered
                  @editInList="editPlacemarkInList"
                  @getPlacemark="showPlacemarkOnMap"
                  @deleteFromList="deleteTrackPointFromList"
                  @openModalInfo="showModalPointInfo"
              />
          </div>

          <div
              v-else-if="!$isMobile"
              class="ion-text-center"
              style="margin-top: 40px;"
          >
            Добавленные объекты не найдены
          </div>
        </div>

        <div class="map">
          <div
              id="map3"
              style="width: 100%; height: 100%;"
              class="map-cursor"
          ></div>
        </div>
      </div>
    </ion-content>

    <ion-footer>
      <ion-row style="padding: 0.3rem 0.5rem; justify-content: flex-end">
        <ion-button
            :style="{ 'width': $isMobile ? '100%' : 'auto' }"
            color="primary"
            @click="addNewTrack"
        >
          Добавить маршрут
        </ion-button>
      </ion-row>
    </ion-footer>
</template>

<script>
import {
  alertController, IonCol, IonRow,
} from '@ionic/vue';
import {
    computed,
    defineComponent,
    ref,
} from 'vue';
import axios from 'axios';
import InputDefault from "@/components/InputDefault.vue";
import AddRouteObject from "@/pages/Tabs/Routes/AddRouteObject.vue";
import MapItemsList from "@/components/MapItemsList.vue";
import ModalPointInfo from "@/components/ModalPointInfo.vue";

export default defineComponent({
    name:       'AddRoute',
    components: {ModalPointInfo, MapItemsList, AddRouteObject, IonCol, IonRow, InputDefault},
    props:      {
        title: { type: String, default: 'Super Modal' },
        isAdmin: { type: Boolean, default: false, },
    },
    emits: ['add-track', 'close'],

    setup() {
        const name = ref(''); // название маршрута
        const desc = ref(''); // описание маршрута
        const allTrackPoints = ref([]); // все метки маршрута
        const isOpenModalAddObject = ref(false);
        const routeObject = ref({
          name: '',
          desc: '',
          point: [],
        });
        const addObject = ref(null);
        const lastActivePlacemark = ref(null);
        const showPlacemark = ref(null);
        const isOpenModalPointInfo = ref(false);
        const editPlacemark = ref(false);
        const isValuesNotEmpty = computed(() => name.value || desc.value || allTrackPoints.value.length);
        
        return {
            name:                 name,
            desc:                 desc,
            isValuesNotEmpty:     isValuesNotEmpty,
            allTrackPoints:       allTrackPoints,
            abbObject:            addObject,
            isOpenModalAddObject: isOpenModalAddObject,
            lastActivePlacemark:  lastActivePlacemark,
            showPlacemark:        showPlacemark,
            isOpenModalPointInfo: isOpenModalPointInfo,
            routeObject:          routeObject,
            editPlacemark:        editPlacemark,
        };
    },

    mounted() {
      const url = 'https://api-maps.yandex.ru/2.1/?apikey=9cf9adcd-0f8e-4b7d-8b15-e0c5dd74b81a&lang=ru_RU';
      let scriptYandexMap = document.querySelector(`script[src="${ url }"]`);
      console.log('find script ymaps on YandexTrackContainer');

      if (!scriptYandexMap) {
        console.log('script ymaps on YandexTrackContainer does not found, create');
        // Установить скрипты для использования яндекс карты
        scriptYandexMap = document.createElement('script');
        scriptYandexMap.setAttribute('src', url);
        document.head.appendChild(scriptYandexMap);
        // Инициализировать яндекс карту
        scriptYandexMap.addEventListener('load', this.initializeYandexMap);
      }
      else {
        if (ymaps) {
          console.log('script ymaps on YandexTrackContainer found success and ymaps found, initialize');
          this.initializeYandexMap();
        }
        else {
          console.log('script ymaps on YandexTrackContainer found success, but not loaded. Wait for it and then initialize');
          scriptYandexMap.addEventListener('load', this.initializeYandexMap);
        }
      }
    },

    methods: {
      editPlacemarkInList(index) {
          this.routeObject = {...this.allTrackPoints[index]};
          this.editPlacemark = true;
          this.isOpenModalAddObject = true;
      },

      updateRouteObject({value, field}) {
          this.routeObject[field] = value;
      },

      showModalPointInfo(placemark) {
          this.routeObject = {...placemark};
          this.isOpenModalPointInfo = true;
      },

      showPlacemarkOnMap(index) {
        this.showPlacemark(index);

        if (this.lastActivePlacemark !== null) {
            this.allTrackPoints[this.lastActivePlacemark].isActive = false;
        }

        if (this.allTrackPoints[index].isActive) {
            this.allTrackPoints[index].isActive = true;
        }

        this.lastActivePlacemark = index;
      },

      deleteTrackPointFromList(index) {
          this.allTrackPoints.splice(index, 1);
          this.addObject();
          this.$success('Объект успешно удален из списка');
      },

      addRouteObjectToList(routeObject) {
        if (this.editPlacemark) {
            this.allTrackPoints[this.lastActivePlacemark] = {...routeObject};
            this.addObject();
        } else {
            this.allTrackPoints.push({...routeObject});
            this.addObject(routeObject);
        }
        this.isOpenModalAddObject = false;
        const message = this.editPlacemark ? 'Объект успешно отредактирован' : 'Объект успешно добавлен в список';
        this.$success(message);
      },

      async initializeYandexMap() {
        console.log('start initializeYandexMap on YandexTrackContainer');

        ymaps.ready({ successCallback: this.init, context: this });
      },

      init() {
        console.log('start init() on YandexTrackContainer');

        let router = {};

        let map = new ymaps.Map('map3', {
          // Координаты центра карты.
          // Порядок по умолчанию: «широта, долгота».
          // Чтобы не определять координаты центра карты вручную,
          // воспользуйтесь инструментом Определение координат.
          center: [ 57.9315097360319, 59.98323203173826 ],
          // Уровень масштабирования. Допустимые значения:
          // от 0 (весь мир) до 19.
          zoom: 11,
        });

        map.cursors.push('inherit');

        map.controls.remove('fullscreenControl');

        map.events.add('click', this.showModalAddObject);

        async function addObject(routeObject) {
          if (this.allTrackPoints.length > 1) {
            router = await ymaps.route(this.allTrackPoints, {
              mapStateAutoApply: true,
            });

            router.getPaths().options.set({
              // балун показывает только информацию о времени в пути с трафиком
              balloonContentLayout: ymaps.templateLayoutFactory.createClass('$[properties.humanJamsTime]'),
              // вы можете настроить внешний вид маршрута
              strokeColor: '0000ffff',
              opacity:     0.9,
            });

            for (let index = 0; index < this.allTrackPoints.length; index++) {
              const wayPoint = router.getWayPoints().get(index);
              wayPoint.properties._data.name = this.allTrackPoints[index].name;
              wayPoint.properties._data.desc = this.allTrackPoints[index].desc;

              if (!index) {
                wayPoint.properties.set('iconCaption', 'Начало маршрута');
                wayPoint.options.set({
                  iconColor: 'green',
                });
                wayPoint.properties._data.name = `Начало маршрута: ${this.allTrackPoints[index].name || this.allTracks[index].point.join(', ')}`
              }
              else if (index === this.allTrackPoints.length - 1) {
                wayPoint.properties.set('iconCaption', 'Конец маршрута');
                wayPoint.options.set({
                  preset:    'islands#governmentIcon',
                  iconColor: 'red',
                });
                wayPoint.properties._data.name = `Конец маршрута: ${this.allTrackPoints[index].name || this.allTracks[index].point.join(', ')}`;
              }
            }

            let BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div style="font-weight: bold">{{ properties.name }}</div>' +
                '<div>{{ properties.desc }}</div>',
            );

            router.getWayPoints().options.set({
              // балун показывает только информацию о времени в пути с трафиком
              balloonContentLayout: BalloonContentLayout,
              // Запретим замену обычного балуна на балун-панель.
              // Если не указывать эту опцию, на картах маленького размера откроется балун-панель.
              balloonPanelMaxMapArea: (this.$isMobile) ? Infinity : 0,
            });

            map.geoObjects.removeAll();

            map.geoObjects.add(router);
          }
          else if (routeObject) {
            const placemark = new ymaps.Placemark(routeObject.point, {
              iconCaption: 'Начало маршрута',
              balloonContent: `<strong>Начало маршрута: ${routeObject.name}<strong>`
            });

            placemark.options.set({
              iconColor: 'green',
            })

            map.geoObjects.add(placemark)
          }
          else if (this.allTrackPoints.length) {
            const placemark = new ymaps.Placemark(this.allTrackPoints[0].point, {
              iconCaption: 'Начало маршрута',
              balloonContent: `<strong>Начало маршрута: ${this.allTrackPoints[0].name}<strong>`
            });

            placemark.options.set({
              iconColor: 'green',
            })

            map.geoObjects.removeAll();

            map.geoObjects.add(placemark)
          }
          else {
            map.geoObjects.removeAll();
          }
        }

        function showPlaceMark(index) {
          let geoObject = {};
          if (this.allTrackPoints.length === 1) {
              geoObject = map.geoObjects.get(index);
              geoObject.options.set('iconColor', 'green');
          } else {
              geoObject = router.getWayPoints().get(index);
          }
          geoObject.events.fire('click', {});
        }

        this.showPlacemark = showPlaceMark;
        this.addObject = addObject;
      },

      showModalAddObject(e) {
        this.routeObject = {
            name: '',
            desc: '',
            point: e.get('coords'),
        };
        this.editPlacemark = false;
        this.isOpenModalAddObject = true;
      },

        async addNewTrack() {
            try {
                const descValidate = this.$refs.descInput.validate();
                const nameValidate = this.$refs.nameInput.validate();
                const pointsValidateLength = this.allTrackPoints.length > 1;

                if (descValidate && nameValidate && pointsValidateLength) {
                  const message = this.isAdmin
                      ? 'Вы уверены, что хотите добавить маршрут?'
                      : 'Вы уверены, что хотите отправить маршрут на верификацию?';

                  const alert = await alertController
                      .create({
                        cssClass: 'add-point-confirm-close',
                        header:   'Подтверждение действия',
                        message:  message,
                        buttons:  [
                          {
                            text:     'Отмена',
                            role:     'cancel',
                            cssClass: 'secondary',
                          },
                          {
                            text:    'Подтвердить',
                            handler: () => {
                              this.$emit('add-track', {
                                name:     this.name,
                                desc:     this.desc,
                                isActive: true,
                                route:    this.allTrackPoints,
                              });
                            },
                          },
                        ],
                      });
                  return alert.present();
                }
                else if (!nameValidate || !descValidate) {
                  this.$error('Проверьте, что указано название и описание маршрута');
                }
                else if (!pointsValidateLength) {
                    this.$error('Для маршрута необходимо добавить минимум 2 объекта!');
                }
            }
            catch (err) {
                console.error('ERROR addNewTrack');
                console.error(err);
                this.$error('Произошла ошибка при добавлении нового маршрута. Попробуйте позже или обратитесь в техподдержку.');
            }
        },
        
        async addNewPoint() {
            this.points.push({ latitude: null, longitude: null, name: '' });
        },
        
        updateFormInputs(val, formKeyName) {
            this[formKeyName] = val;
        },
        
        updateFormPointsInputs(val, index, formKeyName) {
            this.points[index][formKeyName] = val;
        },
        
        async closeModal() {
            if (this.isValuesNotEmpty) {
                await this.presentAlertConfirm('Вы уверены, что хотите закрыть окно добавления маршрута? Все несохраненные данные будут утеряны!');
            }
            else {
                this.$emit('close');
            }
        },
        
        async presentAlertConfirm(message) {
            const alert = await alertController
                .create({
                    cssClass: 'add-point-confirm-close',
                    header:   'Подтверждение действия',
                    message:  message,
                    buttons:  [
                        {
                            text:     'Отмена',
                            role:     'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text:    'Подтвердить',
                            handler: () => {
                                this.$emit('close');
                            },
                        },
                    ],
                });
            return alert.present();
        },
    },
});
</script>

<style>
.point-number {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    padding-right: 10px;
}

#map3 {
  width: 100%;
  height: 100%;
}

.map-cursor {
  cursor: url('../../../../public/assets/images/cursor.png') 25 45, auto;
}

.sidebar {
  width: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: 0 2px 3px 1px rgb(0 0 0 / 20%);
}

.sidebar__list {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
}

.sidebar__heading {
  margin: 16px;
  font-weight: 700;
}

.map-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.map-container > .map-list > ion-list {
  flex: 1 1 auto;
}

.map-container > .map {
  width: 100%;
}

@media (min-width: 1536px) {

}

@media (min-width: 1280px) {
  .sidebar {
    width: 30%!important;
  }

  .sidebar__list {
      height: 70%!important;
  }

  .map-container > .map {
    width: 70%!important;
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .sidebar {
    width: 40%;
    height: 100%;
  }

  .sidebar__list {
    height: 60%;
  }

  .map-container > .map {
    width: 60%;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 640px) {

}

@media (max-width: 767px) {
    .point-number {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        margin: 15px 0;
        padding-right: 10px;
    }
}

</style>
