<template>
  <ion-header>
    <ion-toolbar>
      <ion-row style="justify-content: space-between; align-items: center; flex-wrap: nowrap">
        <ion-col style="margin-left: 18px;">
          <h4 style="margin: 16px 0;">
            {{ edit ? 'Редактирование объекта' : 'Добавление объекта' }}
          </h4>
        </ion-col>

        <ion-col size="auto">
          <ion-button
              fill="clear"
              @click="closeModal"
          >
            <ion-icon
                slot="icon-only"
                color="dark"
                name="close"
            ></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>

  <ion-content
    ref="addObjectForm"
    class="ion-padding"
  >
    <InputDefault
        ref="nameInput"
        :auto-grow="$isMobile"
        :model-value="routeObject.name"
        :rules="[ v => !!v || 'Укажите название объекта' ]"
        hide-length
        label="Название объекта"
        position="floating"
        required
        @update:modelValue="val => updateFormInputs(val, 'name')"
    />

    <InputDefault
        ref="descInput"
        :max-length="5000"
        :model-value="routeObject.desc"
        :rules="[ v => !!v || 'Укажите описание объекта' ]"
        label="Описание"
        position="floating"
        auto-grow
        required
        @update:modelValue="val => updateFormInputs(val, 'desc')"
    />

    <ion-row>
      <ion-col
            size="6"
            size-md
            style="padding: 0;"
        >
            <InputDefault
                ref="latitudeInput"
                :model-value="routeObject.point[0]"
                :rules="[ v => !!v || 'Укажите широту' ]"
                input-numbers
                label="Широта"
                pattern="[0-9]"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'latitude')"
            />
        </ion-col>

        <ion-col
            size="6"
            size-md
            style="padding: 0;"
        >
            <InputDefault
                ref="longitudeInput"
                :model-value="routeObject.point[1]"
                :rules="[ v => !!v || 'Укажите долготу' ]"
                input-numbers
                label="Долгота"
                pattern="[0-9]"
                required
                type="tel"
                @update:modelValue="val => updateFormInputs(val, 'longitude')"
            />
        </ion-col>
    </ion-row>
  </ion-content>

  <ion-footer>
    <ion-row style="padding: 0.3rem 0.5rem; justify-content: flex-end">
      <ion-button
          color="primary"
          @click="addRouteObject"
      >
        {{ edit ? 'Редактировать объект' : 'Добавить объект' }}
      </ion-button>
    </ion-row>
  </ion-footer>
</template>

<script setup>
import {ref} from 'vue';
import InputDefault from "@/components/InputDefault.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  routeObject: {
    type: Object,
    default() {
      return {
        name:   '',
        desc:   '',
        coords: [],
      };
    }
  },
  coords: {
    type:    Array,
    default() {
      return [];
    },
  },
  edit: {
    type:    Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:model-value', 'add-to-list', 'update:route-object']);

const nameInput = ref(null);
const descInput = ref(null);
const latitudeInput = ref(null);
const longitudeInput = ref(null);

function closeModal() {
  emit('update:model-value', false);
}

function updateFormInputs(value, field) {
  emit('update:route-object', { value, field });
}

function addRouteObject() {
  const nameValidate = nameInput.value.validate();
  const descValidate = descInput.value.validate();
  const latitudeValidate = latitudeInput.value.validate();
  const longitudeValidate = longitudeInput.value.validate();

  if (nameValidate && descValidate && latitudeValidate && longitudeValidate) {
    const object = {
      ...props.routeObject,
      type:     'wayPoint',
      isActive: false,
    };
    emit('add-to-list', object);
  }
}

</script>

<style scoped>

</style>