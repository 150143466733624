<template>
    <ion-spinner
        v-if="!loadMap"
        class="loader"
    />
    
    <div
        v-else
        class="map-container"
    >
        <div class="map-list">
            <div class="items">
                <MapItemsList
                    :placemarks="allTracks"
                    :is-admin="isAdmin"
                    @getPlacemark="showTrackOnMap"
                    @openModalInfo="openModalPointInfo"
                    @confirmPlacemark="index => showAlertConfirm(index)"
                    @discardPlacemark="index => showAlertConfirm(index, true)"
                >
                    <template v-slot:empty-list-message>
                        Маршруты никто не добавлял или еще не прошли модерацию. Попробуйте получить их позже.
                    </template>
                </MapItemsList>
            </div>
            
            <ion-text
                class="buttonAdd"
                color="primary"
                @click="openModal"
            >
                + Добавить маршрут
            </ion-text>
        </div>
        
        <div class="map">
            <div
                id="map2"
                style="width: 100%; height: 100%;"
            ></div>
        </div>
        
        <ion-modal
            :is-open="isOpenModalAddTrack"
            css-class="my-custom-class fullscreen"
            @didDismiss="isOpenModalAddTrack = false"
        >
            <AddTrack
                :is-admin="isAdmin"
                @add-track="addNewRouteToList"
                @close="isOpenModalAddTrack = false"
            />
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalTracksList"
            css-class="my-custom-class"
            @didDismiss="isOpenModalTracksList = false"
        >
            <MapItemsListDialog
                :placemarks="allTracks"
                :is-admin="isAdmin"
                @close="isOpenModalTracksList = false"
                @getPlacemark="showTrackOnMap"
                @openAddPoint="openModal"
                @openModalInfo="openModalPointInfo"
                @confirmPlacemark="index => showAlertConfirm(index)"
                @discardPlacemark="index => showAlertConfirm(index, true)"
            >
                <template v-slot:title>
                    Список маршрутов
                </template>
                
                <template v-slot:add-title>
                    + Добавить маршрут
                </template>
                
                <template v-slot:empty-list-message>
                    Маршруты никто не добавлял или еще не прошли модерацию. Попробуйте получить их позже.
                </template>
            </MapItemsListDialog>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalTrackInfo"
            css-class="my-custom-class"
            @didDismiss="isOpenModalTrackInfo = false"
        >
            <ModalPointInfo
                :desc="trackInfoObj.desc"
                :name="trackInfoObj.name"
                :passport="trackInfoObj.passport"
                :id="trackInfoObj.id"
                :is-admin="isAdmin"
                disable-image
                title="Информация о маршруте"
                @close="isOpenModalTrackInfo = false"
            ></ModalPointInfo>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalTrackPoints"
            css-class="my-custom-class"
            @didDismiss="isOpenModalTrackPoints = false"
        >
            <MapItemsListDialog
                :placemarks="allTrackPoints"
                disable-add-buttons
                @close="isOpenModalTrackPoints = false"
                @getPlacemark="showTrackPoint"
                @openModalInfo="openModalTrackPointInfo"
            >
                <template v-slot:title>
                    {{ generateModalNameOfRoutePoints }}
                </template>
                
                <template v-slot:map-list-item-title="slots">
                    <b>Точка #{{ slots.placemarkIndex + 1 }}.</b> {{ slots.placemarkName }}
                </template>
                
                <template v-slot:empty-list-message>
                    Маршруты никто не добавлял или еще не прошли модерацию. Попробуйте получить их позже.
                </template>
            </MapItemsListDialog>
        </ion-modal>
        
        <ion-modal
            :is-open="isOpenModalTrackPointInfo"
            css-class="my-custom-class"
            @didDismiss="isOpenModalTrackPointInfo = false"
        >
            <ModalPointInfo
                :coords="trackPointInfoObj.coords"
                :desc="trackPointInfoObj.desc"
                :image="trackPointInfoObj.image"
                :name="trackPointInfoObj.name"
                :place="trackPointInfoObj.place"
                title="Информация о точке маршрута"
                @close="isOpenModalTrackPointInfo = false"
            ></ModalPointInfo>
        </ion-modal>
    </div>
</template>

<script>
import axios from 'axios';
import AddTrack from '@/pages/Tabs/Routes/AddRoute.vue';
import ModalPointInfo from '@/components/ModalPointInfo.vue';
import MapItemsListDialog from '@/components/MapItemsListDialog.vue';
import MapItemsList from '@/components/MapItemsList.vue';
import {alertController} from "@ionic/vue";
import {CONSTANTS} from "@/boot/constants";

export default {
    name:       'YandexRouteContainer',
    components: {
        ModalPointInfo,
        AddTrack,
        MapItemsListDialog,
        MapItemsList,
    },
    props: {
        isAdmin: {
            type:    Boolean,
            default: false,
        },
    },
    
    inject: [ 'neededOrganizations' ],
    
    data: () => ({
        lastActiveTrack: null,
        allTracks:       [],
        erros:           [],
        loadMap:         false,
        map:             null,
        msg:             'This is a button.',
        selectRoute:     null,
        
        isOpenModalAddTrack: false,
        
        isOpenModalTracksList: false,
        
        isOpenModalTrackPoints:    false,
        allTrackPoints:            [],
        showTrackPointFnc:         null,
        isOpenModalTrackPointInfo: false,
        trackPointInfoObj:         {
            name:   null,
            desc:   null,
            coords: null,
            place:  null,
            image:  null,
        },
        
        isOpenModalTrackInfo: false,
        trackInfoObj:         {
            name:   null,
            desc:   null,
            coords: null,
            place:  null,
            image:  null,
            id:     null,
        },
    }),
    
    mounted() {
        const url = 'https://api-maps.yandex.ru/2.1/?apikey=9cf9adcd-0f8e-4b7d-8b15-e0c5dd74b81a&lang=ru_RU';
        let scriptYandexMap = document.querySelector(`script[src="${ url }"]`);
        console.log('find script ymaps on YandexTrackContainer');
        
        if (!scriptYandexMap) {
            console.log('script ymaps on YandexTrackContainer does not found, create');
            // Установить скрипты для использования яндекс карты
            scriptYandexMap = document.createElement('script');
            scriptYandexMap.setAttribute('src', url);
            document.head.appendChild(scriptYandexMap);
            // Инициализировать яндекс карту
            scriptYandexMap.addEventListener('load', this.initializeYandexMap);
        }
        else {
            if (ymaps) {
                console.log('script ymaps on YandexTrackContainer found success and ymaps found, initialize');
                this.initializeYandexMap();
            }
            else {
                console.log('script ymaps on YandexTrackContainer found success, but not loaded. Wait for it and then initialize');
                scriptYandexMap.addEventListener('load', this.initializeYandexMap);
            }
        }
    },
    
    computed: {
        generateModalNameOfRoutePoints() {
            const prefix = 'Список точек маршрута';
            return (this.lastActiveTrack != null) ? `${ prefix } ${ this.allTracks[this.lastActiveTrack].name }` : prefix;
        },
    },
    
    methods: {
        async addNewRouteToList(route) {
          const request = {
              name: route.name,
              desc: route.desc,
              route: route.route,
          };
          try {
              const response = await axios.post('/api/route/add', request);

              if (response.data.data.success) {
                if (this.isAdmin) {
                    this.isOpenModalAddTrack = false;
                    this.allTracks.unshift({
                        ...request,
                        id: response.data.data.id,
                    });
                    this.$success('Маршрут успешно добавлен');
                }
                else {
                    this.isOpenModalAddTrack = false;
                    this.$success('Маршрут успешно отправлен на верификацию');
                }
              }
          }
          catch(error) {
              console.error('ERROR ADD ROUTE');
              console.error(error);
              this.$error('Произошла ошибка при добавлении нового маршрута. Попробуйте позже или обратитесь в техподдержку.')
          }
        },

        async showTrackPoint(number) {
            this.isOpenModalTrackPoints = false;
            this.showTrackPointFnc(number);
        },
        
        async showTrackOnMap(number) {
            this.isOpenModalTracksList = false;
            await this.selectRoute(number);
            
            if (this.lastActiveTrack != null) {
                this.allTracks[this.lastActiveTrack].isActive = false;
                this.allTrackPoints = [];
            }
            
            this.allTracks[number].isActive = true;
            this.lastActiveTrack = number;
            this.allTrackPoints = this.allTracks[number].route;
        },
        
        async initializeYandexMap() {
            console.log('start initializeYandexMap on YandexTrackContainer');
            let response = null;
            
            try {
                if (this.isAdmin) {
                    response = await axios.post('/api/route/show/all')
                }
                else {
                    response = await axios.post('/api/route/show');
                }

                this.allTracks = response.data.data.map(v => ({ ...v, isActive: false }));
            }
            catch (err) {
                console.error('ERROR initializeYandexMap YandexTrackContainer');
                console.error(err);
                this.erros.push(err);
                this.$error('Произошла ошибка при получении списка маршрутов. Попробуйте позже или обратитесь в техподдержку.');
            }
            
            this.loadMap = true;
            let maps = ymaps;
            
            maps.ready({ successCallback: init, context: this });
            
            function init() {
                console.log('start init() on YandexTrackContainer');
                
                let router = {};
                // Создание карты.
                let map = new maps.Map('map2', {
                    // Координаты центра карты.
                    // Порядок по умолчанию: «широта, долгота».
                    // Чтобы не определять координаты центра карты вручную,
                    // воспользуйтесь инструментом Определение координат.
                    center: [ 57.9315097360319, 59.98323203173826 ],
                    // Уровень масштабирования. Допустимые значения:
                    // от 0 (весь мир) до 19.
                    zoom: 11,
                });
                
                if (this.$isMobile) {
                    const firstButton = new ymaps.control.Button({
                        data:    {
                            content: 'Открыть список маршрутов',
                        },
                        options: {
                            maxWidth: 150,
                            size:     'large',
                            layout:   ymaps.templateLayoutFactory.createClass(
                                // Если кнопка не нажата, применяется CSS стиль 'myButton'.
                                // Если кнопка нажата, к ней применятся CSS-стили 'myButton' и 'myButtonSelected'.
                                
                                '<div class=\'myButton {% if state.selected %}myButtonSelected{% endif %}\' ' +
                                '    title=\'{{ data.title }}\' ' +
                                '    style=\'background-color: #e2f5ff; ' +
                                '       border-radius: 3px; ' +
                                '       display: flex;' +
                                '       cursor: pointer;' +
                                '       overflow: hidden;' +
                                '       padding: 8px 5px;' +
                                '       max-width: 200px;' +
                                '       align-items: center;' +
                                '       box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);' +
                                '       font-family: Arial; ' +
                                '       font-size: 14px; ' +
                                '       font-weight: 400; ' +
                                '       font-style: normal; ' +
                                '       font-stretch: normal;\'' +
                                '>' +
                                '   <ion-icon name="analytics" style=\'font-size: 25px; margin-right: 5px;\'></ion-icon>' +
                                '   {{ data.content }}' +
                                '</div>',
                            ),
                        },
                    });
                    
                    map.controls.add(firstButton, { float: 'none', position: { bottom: '40px', left: '8px' } });
                    
                    firstButton.events.add([ 'click' ], async () => {
                        await this.openModalTracksList();
                    });
                }
                
                this.map = map;
                
                async function selectRoute(number) {
                    console.log(number);
                    // eslint-disable-next-line no-unused-vars
                    let isPersonPointAdded = false;
                    let startIndexOfPoints = 0; // 0 - начинаем смотреть с самого начала, 1 - смотрим со второго, т.к. первая точка - текущее местоположение
                    
                    try {
                        // Если в списке точек маршрута есть точка "Ваше местоположение", то удаляем, и потом добавим снова
                        if (this.allTracks[number].route[0] && this.allTracks[number].route[0].personPoint) {
                            this.allTracks[number].route.shift();
                        }
                        
                        try {
                            // Получаем текущее местоположение
                            const personPoint = await maps.geolocation.get({ provider: 'auto', mapStateAutoApply: true });
                            
                            // Добавляем точку текущего местоположения в самое начало
                            this.allTracks[number].route.unshift({
                                type:        'wayPoint',
                                point:       personPoint.geoObjects.position,
                                name:        'Ваше местоположение',
                                personPoint: true,
                            });
                            isPersonPointAdded = true;
                            startIndexOfPoints = 1;
                        }
                        catch (err) {
                            this.$error('Произошла ошибка при получении вашего текущего местоположения');
                            console.error('ERROR GET CURRENT LOCATION OF USER');
                            console.error(err);
                        }
                        
                        // Добавляем маршрут на карту
                        router = await maps.route(this.allTracks[number].route, {
                            mapStateAutoApply: true,
                        });
                        
                        // Меняем балун маршрута при нажатии на него (будет показываться время проезда по отрезку маршрута)
                        router.getPaths().options.set({
                            // балун показывает только информацию о времени в пути с трафиком
                            balloonContentLayout: maps.templateLayoutFactory.createClass('$[properties.humanJamsTime]'),
                            // вы можете настроить внешний вид маршрута
                            strokeColor: '0000ffff',
                            opacity:     0.9,
                        });
                        
                        // Проходим по всем точкам, и на карте добавляем названия точек, чтобы не было координат
                        for (let routeIndex in this.allTracks[number].route) {
                          console.log(routeIndex)
                            const wayPoint = router.getWayPoints().get(routeIndex);
                            wayPoint.properties._data.name = this.allTracks[number].route[routeIndex].name || this.allTracks[number].route[routeIndex].point.join(', ');
                            wayPoint.properties._data.desc = this.allTracks[number].route[routeIndex].desc || '';
                            
                            // Первая точка маршрута находится по индексу 1, потому что по 0 индексу находится местоположение человека
                            if (routeIndex == startIndexOfPoints) {
                                wayPoint.properties.set('iconCaption', 'Начало маршрута');
                                wayPoint.options.set({
                                    iconColor: 'green',
                                });
                                wayPoint.properties._data.name = `Начало маршрута: ${ this.allTracks[number].route[routeIndex].name || this.allTracks[number].route[routeIndex].point.join(', ') }`;
                            }
                            else if (routeIndex == this.allTracks[number].route.length - 1) {
                                wayPoint.properties.set('iconCaption', 'Конец маршрута');
                                wayPoint.options.set({
                                    preset:    'islands#governmentIcon',
                                    iconColor: 'red',
                                });
                                wayPoint.properties._data.name = `Конец маршрута: ${ this.allTracks[number].route[routeIndex].name || this.allTracks[number].route[routeIndex].point.join(', ') }`;
                            }
                            else if (this.allTracks[number].route[routeIndex].personPoint) {
                                wayPoint.options.set({
                                    preset: 'islands#brownPersonIcon',
                                });
                            }
                        }
                        
                        // Создание макета содержимого балуна.
                        // Макет создается с помощью фабрики макетов с помощью текстового шаблона.
                        // eslint-disable-next-line no-unused-vars
                        let BalloonContentLayout = maps.templateLayoutFactory.createClass(
                            '<div style="font-weight: bold">{{ properties.name }}</div>' +
                            '<div>{{ properties.desc }}</div>',
                        );
                        
                        router.getWayPoints().options.set({
                            // балун показывает только информацию о времени в пути с трафиком
                            balloonContentLayout: BalloonContentLayout,
                            // Запретим замену обычного балуна на балун-панель.
                            // Если не указывать эту опцию, на картах маленького размера откроется балун-панель.
                            balloonPanelMaxMapArea: (this.$isMobile) ? Infinity : 0,
                        });
                        
                        // Очищаем все, что ранее было на карте
                        map.geoObjects.removeAll();
                        // добавляем маршрут на карту
                        map.geoObjects.add(router);
                        
                        // добавляем кнопку "Показать точки маршрута"
                        if (this.$isMobile) {
                            const button = new ymaps.control.Button({
                                data:    {
                                    content: 'Все точки маршрута',
                                },
                                options: {
                                    maxWidth: 150,
                                    size:     'large',
                                    layout:   ymaps.templateLayoutFactory.createClass(
                                        '<div class=\'myButton {% if state.selected %}myButtonSelected{% endif %}\' ' +
                                        '    title=\'{{ data.title }}\' ' +
                                        '    style=\'background-color: #e2f5ff; ' +
                                        '       border-radius: 3px; ' +
                                        '       display: flex;' +
                                        '       cursor: pointer;' +
                                        '       overflow: hidden;' +
                                        '       padding: 8px 5px;' +
                                        '       max-width: 200px;' +
                                        '       align-items: center;' +
                                        '       box-shadow: 0 1px 2px 1px rgb(0 0 0 / 15%), 0 2px 5px -3px rgb(0 0 0 / 15%);' +
                                        '       font-family: Arial; ' +
                                        '       font-size: 14px; ' +
                                        '       font-weight: 400; ' +
                                        '       font-style: normal; ' +
                                        '       font-stretch: normal;\'' +
                                        '>' +
                                        '   {{ data.content }}' +
                                        '</div>',
                                    ),
                                },
                            });
                            
                            map.controls.add(button, { float: 'none', position: { bottom: '97px', left: '8px' } });
                            
                            button.events.add([ 'click' ], async () => {
                                await this.openModalTrackPoints();
                            });
                        }
                        
                        this.showTrackPointFnc = (number) => {
                            console.log('click' + number);
                            const wayPoint = router.getWayPoints().get(number);
                            wayPoint.events.fire('click', {});
                        };
                    }
                    catch (err) {
                        console.error('selectRoute ERROR');
                        console.error(err);
                    }
                }
                
                this.selectRoute = selectRoute;
            }
        },
        
        async openModal() {
            this.isOpenModalAddTrack = true;
            
            // const modal = await modalController
            //     .create({
            //         component:      AddTrack,
            //         cssClass:       'addTrack',
            //         componentProps: {
            //             title: 'Добавить маршрут',
            //         },
            //     });
            // return modal.present();
        },
        
        async openModalPointInfo(track) {
            event.preventDefault();
            event.stopPropagation();
            
            this.trackInfoObj.desc = track.desc;
            this.trackInfoObj.name = track.name;
            this.trackInfoObj.passport = track.passport;
            this.trackInfoObj.id = track.id;
            this.isOpenModalTrackInfo = true;
        },
        
        async openModalTracksList() {
            this.isOpenModalTracksList = true;
        },
        
        async openModalTrackPoints() {
            this.isOpenModalTrackPoints = true;
        },
        
        async openModalTrackPointInfo(placemark) {
            event.preventDefault();
            event.stopPropagation();
            
            this.trackPointInfoObj.desc = placemark.desc;
            this.trackPointInfoObj.coords = placemark.point;
            this.trackPointInfoObj.name = placemark.name;
            this.trackPointInfoObj.image = placemark.img;
            this.trackPointInfoObj.place = placemark.place;
            this.isOpenModalTrackPointInfo = true;
        },

        async showAlertConfirm(index, discard = false) {
          const action = discard ? 'отклонить' : 'подтвердить';
          const alert = await alertController
              .create({
                cssClass: 'add-point-confirm-close',
                header:   'Подтверждение действия',
                message:  `Вы уверены, что хотите ${action} маршрут ${this.allTracks[index].name || ''}?`,
                buttons:  [
                  {
                    text:     'Отмена',
                    role:     'cancel',
                    cssClass: 'secondary',
                  },
                  {
                    text:    'Подтвердить',
                    handler: () => discard ? this.discardRoutePlacemark(index) : this.confirmRoutePlacemark(index),
                  },
                ],
              });
          return alert.present();
        },

        async confirmRoutePlacemark(index) {
            const request = {
                id: this.allTracks[index].id,
            };
            try {
                const response = await axios.post('/api/route/confirm', request);
                if (response.data.data.success) {
                    this.allTracks[index].status = CONSTANTS.ROUTE_STATUSES.CONFIRMED;
                    this.$success('Маршрут успешно подтвержден');
                }
            }
            catch(error) {
                console.error(error);
                console.error('ERROR CONFIRM PLACEMARK');
                this.$error('Не удалось подтвердить маршрут. Попробуйте позже или обратитесь к администратору');
            }
        },

        async discardRoutePlacemark(index) {
            const request = {
                id: this.allTracks[index].id,
            };
            try {
                const response = await axios.post('/api/route/reject', request);
                if (response.data.data.success) {
                    this.allTracks[index].status = CONSTANTS.ROUTE_STATUSES.REJECTED;
                    this.$success('Маршрут успешно отклонен');
                }
            }
            catch(error) {
                console.error(error);
                console.error('ERROR REJECT PLACEMARK');
                this.$error('Не удалось отклонить маршрут. Попробуйте позже или обратитесь к администратору');
            }
        },
    },
};
</script>

<style scoped>
#map {
    width: 100%;
    height: 100%;
}

.full {
    width: 100%;
    height: 100%;
}

.loader {
    left: 50%;
    top: 50%;
}

.map-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.map-container > .map-list {
    display: none;
    flex-direction: column;
}

.map-container > .map-list > ion-list {
    flex: 1 1 auto;
}

.map-container > .map {
    width: 100%;
}

@media (min-width: 1536px) {

}

@media (min-width: 1280px) {

}

@media (min-width: 1024px) {
    .map-container > .map-list {
        display: flex;
        width: 30%;
    }
    
    .map-container > .map {
        width: 70%;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 640px) {

}

.buttonAdd {
    text-align: center;
    cursor: pointer;
    padding: 1rem 0.5rem;
    box-shadow: -5px -2px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    background-color: #99DDFF48;
}

.buttonAdd::before {
    transform: scaleX(0);
    transform-origin: bottom right;
}

.buttonAdd:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.buttonAdd::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0 0 0 0;
    background: hsl(200 100% 80%);
    z-index: -1;
    transition: transform .3s ease;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}
</style>
